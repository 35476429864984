<template>
  <div>
    <!-- <headers /> -->
    <container />
    <!-- <footers /> -->
  </div>
</template>

<script>
// import headers from '@/components/header';
import container from './index/index.vue'
// import footers from '@/components/footer'
export default {
  name: 'index',
  components: {
    // headers,
    container,
    // footers
  },
  mounted() {

  },
  methods: {
    async get() {
      // let ers = await this.$api.user.setInfo({
      //   "password": "123456",
      //   "phone": "16666666666"
      // })
      // let ers = await this.$api.user.setInfo()
      // console.log(ers);
    }
  },
}
</script>

<style scoped lang="scss">
</style>