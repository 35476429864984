<template>
  <div class="mask" v-if="isShow">
    <div class="container">
      <div class="container_l">
        <div
          class="img_content"
        >
          <img :src="item.path" class="big_img" v-for="(item, index) in info.files" :key="index" :style="{ opacity: `${currentImg == index?1:0}` }">
        </div>
        <div class="footer">
          <div class="footer_btn_l" @click="changeSlide('left')">←</div>
          <div class="footer_content" ref="footerContent">
            <img
              ref="imgItem"
              :class="index == currentImg?'img_active':''"
              v-lazy="item.path"
              :style="{ transform: `translateX(${translateX}px)` }"
              v-for="(item, index) in info.files"
              :key="index"
              @mousemove="changeCurrentImg(index)"
            />
          </div>
          <div class="footer_btn_r" @click="changeSlide('right')">→</div>
        </div>
      </div>
      <div class="container_r">
        <div class="title">{{ info.fx_name }}</div>
        <div class="square">
          <span>{{ info.acreage_min }}-{{ info.acreage_max }}㎡</span>
          <span>|</span>
          <span>{{ info.rental_type == 1 ? "整租" : "合租" }}</span>
        </div>
        <div class="price">
          <span class="count">{{ info.price_min }}-{{ info.price_max }}</span>
          <span class="unit">元/月</span>
        </div>
        <div class="tag">
          <div
            class="tag_item"
            v-for="(item, index) in info.appliances"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
        <div class="subtitle" v-if="info.brief_info">房源描述</div>
        <div class="content" :title="info.brief_info">
          {{ info.brief_info }}
        </div>
        <div class="btn_div">
          <img src="../../assets/image/dh.png" />
          电话预约：{{ info.booking_advice_info?.booking_line }}
        </div>
        <div class="btn_div yuyue">
          预约看房
          <div style="position: relative">
            <div class="qr_code">
              <img
                :src="info.booking_advice_info?.qrcode?.src"
                :title="info.booking_advice_info?.qrcode?.title"
              />
              <span>手机扫一扫上方二维码，预约看房</span>
            </div>
          </div>
        </div>
        <div class="subtitle">费用信息</div>
        <div class="cost">
          <div>管理费：{{ info?.glf || 0.0 }}元</div>
          <div>水费：{{ info.water_fee || 0.0 }}元/吨</div>
          <div>电费：{{ info.electricity_fee || 0.0 }}元/度</div>
          <div>网络费：{{ info?.network_fee || 0.0 }}元/月</div>
        </div>
        <div class="subtitle">房型配置</div>
        <div class="icon_list">
          <div
            class="icon_list_item"
            v-for="(item, index) in filesList"
            :key="index"
          >
            <img :src="require(`@/assets/icon/${item.icon}.png`)" />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <!-- 关闭按钮 -->
      <img src="../../assets/image/gb.png" class="close" @click="closeDialog" />
    </div>
    <small-loading v-if="isLoading" />
  </div>
</template>
<script>
import smallLoading from "@/components/loading/smallLoading.vue";
import { getHouseDetail } from "@/api/lookHouse";
export default {
  components: {
    smallLoading,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isShow(n) {
      if (n) {
        this.stop();
        this.currentImg = 0
        this.executeAnimation()
      } else {
        this.move();
      }
    },
  },
  data() {
    return {
      translateX: 0,
      current: 0,
      currentImg: 0,
      iconList: [
        { icon: "chuang", name: "床" },
        { icon: "rsq", name: "热水器" },
        { icon: "bx", name: "冰箱" },
        { icon: "xyj", name: "洗衣机" },
        { icon: "yyj", name: "油烟机" },
        { icon: "sf", name: "沙发" },
        { icon: "kt", name: "空调" },
        { icon: "yz", name: "餐椅" },
        { icon: "xg", name: "鞋柜" },
        { icon: "czg", name: "餐桌" },
        { icon: "yg", name: "衣柜" },
        { icon: "wbl", name: "微波炉" },
      ],
      detailId: null,
      info: {},
      filesList: [],
      isLoading: false,
      timer: {}
    };
  },
  methods: {
    async initData() {
      try {
        this.isLoading = true;
        const { data: res } = await getHouseDetail(this.detailId);
        this.info = res;
        let iconList = [];
        this.iconList.forEach((item) => {
          res.furniture.forEach((item2) => {
            if (item.name == item2) {
              iconList.push(item);
            }
          });
        });
        this.filesList = iconList;
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 800);
      }
    },
    stop() {
      let mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },
    move() {
      let mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
    closeDialog() {
      this.$parent.isShow = false;
    },
    changeSlide(type) {
      let width = this.$refs.imgItem[0]?.offsetWidth || 0;
      let border = ''
      if(this.$refs.footerContent) {
        border = window.getComputedStyle(this.$refs.footerContent).columnGap.split('px')[0]
      }
      //元素移动的距离
      let distance = width + Number(border);
      this.executeAnimation()
      if (type == "left") {
        if(this.currentImg == 0) {
          this.currentImg = this.info?.files?.length - 1
          this.translateX = (this.info?.files?.length - 4) * distance * -1
        }else {
          this.currentImg = this.currentImg - 1
          if(this.currentImg > 2) {
            this.translateX = this.translateX + distance
          }
        }
      } else if (type == "right") {
        if(this.currentImg == this.info?.files?.length - 1) {
          this.currentImg = 0
        }else {
          this.currentImg = this.currentImg + 1
        }
        //滑动
        if(this.currentImg > 3) {
          this.translateX = this.translateX - distance;
        }else {
          this.translateX = 0
        }
      }
    },
    changeCurrentImg(i) {
      this.currentImg = i
    },
    executeAnimation() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.changeSlide('right')
      }, 3000);
    }
  },
};
</script>
<style scoped lang="scss">
.img_active {
  border: 2px solid #ffa71d;
  box-sizing: border-box;
}
.mask {
  position: fixed;
  inset: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    width: 1000px;
    height: 632px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    display: flex;
    column-gap: 20px;
    .close {
      width: 50px;
      height: 50px;
      position: absolute;
      top: -54px;
      right: -54px;
      cursor: pointer;
    }
    &_l {
      width: 667px;
      height: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 18px;
      .img_content {
        width: 100%;
        height: 472px;
        overflow: hidden;
        position: relative;
        .big_img {
          width: 100%;
          height: 100%!important;
          object-fit: cover;
          position: absolute;
          inset: 0;
          transition: all 1s ease;
        }
      }
      .footer {
        display: flex;
        column-gap: 20px;
        &_content {
          flex: 1;
          overflow: hidden;
          display: flex;
          column-gap: 19px;
          img {
            min-width: 125px;
            max-width: 125px;
            height: 100px;
            object-fit: cover;
            cursor: pointer;
            transition: all 0.3s ease;
          }
        }
        &_btn_l,
        &_btn_r {
          width: 35px;
          height: 100px;
          background: #ffa71d;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          -webkit-user-select: none; /* Safari */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* IE 10+/Edge */
          user-select: none;
        }
      }
    }
    &_r {
      flex: 1;
      height: 100%;
      color: #333333;
      .title {
        font-size: 22px;
        font-weight: bold;
      }
      .subtitle {
        font-size: 18px;
        font-weight: bold;
      }
      .square {
        color: #666666;
        font-size: 14px;
        margin: 10px 0;
        display: flex;
        column-gap: 10px;
        align-items: center;
      }
      .price {
        .count {
          color: #ff4d4d;
          font-size: 22px;
          font-weight: bold;
        }
        .unit {
          font-size: 14px;
        }
      }
      .tag {
        display: flex;
        column-gap: 10px;
        flex-wrap: wrap;
        row-gap: 5px;
        margin-top: 5px;
        margin-bottom: 10px;
        &_item {
          padding: 3px 5px;
          box-sizing: border-box;
          background: #f4f4f4;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          flex-shrink: 0;
        }
      }
      .content {
        font-size: 12px;
        text-indent: 2em;
        line-height: 18px;
        margin: 10px 0;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .btn_div {
        width: 100%;
        height: 40px;
        background: #999999;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        column-gap: 10px;
        border-radius: 4px;
        margin: 10px 0;
        position: relative;
        img {
          width: 18px;
          height: 18px;
        }
        .qr_code {
          width: 250px;
          height: 250px;
          padding: 20px 20px 34px 20px;
          box-sizing: border-box;
          background-color: #fff;
          position: absolute;
          bottom: 42px;
          left: 50%;
          z-index: 9999999;
          display: none;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          span {
            font-size: 14px;
            color: #666666;
            display: block;
            white-space: nowrap; /* 防止文本换行 */
            overflow: hidden; /* 溢出部分隐藏 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
          &::after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-top: 10px solid #fff;
            border-bottom: 14px solid transparent;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            position: absolute;
            bottom: -22px;
            left: 10%;
          }
        }
      }
      .yuyue {
        background: #ffa71d;

        &:hover {
          .qr_code {
            display: block;
          }
        }
      }
      .cost {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 10px;
        font-size: 12px;
        color: #666;
        margin: 10px 0;
      }
      .icon_list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;
        color: #666;
        margin-top: 10px;
        &_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
}
</style>